<template>
  <div :class="{ relative: props.showItemPosition }">
    <component
      :is="isNuxtLink"
      :to="props.link"
      class="order-2 flex aspect-square items-center justify-center sm:order-1"
      :style="{
        height: `${ProductImageDimensions.height}px`,
      }"
    >
      <UiImage
        :style="{ maxHeight: `${ProductImageDimensions.height}px`, maxWidth: `${ProductImageDimensions.width}px` }"
        class="w-auto"
        v-bind="props.image"
        loading="lazy"
      />
    </component>
    <button
      v-if="props.enableShowItemImageOnClick"
      class="group order-1 flex size-3 items-center justify-center border border-primary leading-none transition-colors hover:bg-blue-100 sm:order-2"
      type="button"
      :style="showItemPositionStyle"
      @click="() => handleShowImage(props.image)"
    >
      <UiIcon
        name="resize"
        :width="8"
        :height="8"
        class="group-hover:fill-white"
      />
    </button>

    <UiGallery
      v-if="isProductImageVisible"
      :media="currentProductImage"
      :initial-slide="0"
      @close="handleCloseImage"
    />
  </div>
</template>

<script setup lang="ts">
import { type CSSProperties, computed, resolveComponent } from 'vue'
import UiImage from '../../../UiImage/UiImage.vue'
import UiIcon from '../../../UiIcon/UiIcon.vue'
import type { ImageInterface } from '../../../UiImage/UiImage.types.ts'
import UiGallery from '../../../UiGallery/UiGallery.vue'
import type { GalleryMedia } from '../../../UiGallery/UiGallery.types'
import type { UiProductImageProps } from './UiProductImage.types'
import { ProductImageDimensions } from './UiProductImage.types'

const props = withDefaults(defineProps<UiProductImageProps>(), {
  link: '',
  enableShowItemImageOnClick: true,
  showItemPosition: undefined,
})

const isProductImageVisible = ref(false)
const currentProductImage = ref<GalleryMedia[]>([])

const showItemPositionStyle = computed(() => {
  if (!props.showItemPosition) { return undefined }

  const baseStyle: CSSProperties = { position: 'absolute' }
  const mergeStyle = (style: CSSProperties) => ({ ...baseStyle, ...style })

  switch (props.showItemPosition) {
    case 'bottom-left':
      return mergeStyle({ bottom: 0, left: 0 })
    case 'bottom-right':
    default:
      return mergeStyle({ bottom: 0, right: 0 })
  }
})

const isNuxtLink = computed(() =>
  props.link ? resolveComponent('nuxt-link') : 'div',
)

function handleShowImage(data: ImageInterface) {
  if (!props.enableShowItemImageOnClick) {
    return
  }
  const { src = '' } = data || {}

  if (!src) {
    return
  }

  currentProductImage.value = [
    {
      publicUrl: src,
      properties: {
        dimensions: {
          width: 1296,
          height: 736,
        },
      },
    },
  ]
  isProductImageVisible.value = true
}

function handleCloseImage() {
  isProductImageVisible.value = false
  currentProductImage.value = []
}
</script>
