import type { ImageInterface } from '../../../UiImage/UiImage.types'

export interface UiProductImageProps {
  image: ImageInterface
  link?: string
  enableShowItemImageOnClick?: boolean
  showItemPosition?: 'bottom-left' | 'bottom-right'
}

export const ProductImageDimensions = {
  width: 76,
  height: 72,
}
